@import "../../utils/colors.scss";
@import "../../utils/media.scss";
@import "../../utils/animation.scss";

.searchContainer {
    z-index: 11;
    top:0%;
    position: relative;
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0.5rem;
    text-align: center;
    @include respond(tab-port) { 
        height: 100%;
    }
}
.searchContent {
    background-color: #fff;
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    border-radius: 13px;
    padding: 0 1rem;
    box-shadow: 0px 2px 21px 0px rgba(0,0,0,0.1);
    input {
        background-color: transparent;
        font-family:MADETOMMY-medium;
        border:none;
        border-radius: 13px;
        padding: 1.5rem 0;
        font-size:16px;
        outline: none;
        text-align: center;
    }
}

.searchItemsContainer {
    background-color: #fff;
    margin: 0 auto;
    width: 90%;
    padding:0 1rem;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
    position: relative;
    border-top: 1px solid rgb(240,241,241);
}
.searchItemContent {
    display: grid;
    grid-template-columns: 10% 90%;
    align-items: center;
    justify-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgb(240,241,241);
    label {
        width: 100%;
        text-align: start;
        position: relative;
        font-family: MADETOMMY;
        font-size: 14px;
        line-height: 19px;
        color:rgba(0,0,0,0.5);
        padding-left:1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
} 

