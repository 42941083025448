@import "../../utils/colors.scss";
@import "../../utils/media.scss";
@import "../../utils/animation.scss";

.providerInfoModal {
    position: absolute;
    z-index: 100;
    background: var(--backgroundThr);
    height: 100%;
    width: 100vw;
    padding:0 14px;
    z-index: 1;
  
    @include respond(tab-port) {
      max-width: 375px;
      max-height: 505px;
      right: 30px;
      top: 60px;
      animation: fadeIn 0.5s ease-in-out 200ms 1 normal backwards;
    }
  
    .modalContainer {
      position: relative;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 8% 8% 74% 10%;
      padding: 2rem 0;
      overflow-x:hidden;
      div {
        h2 {
          font-family: MADETOMMY-bold;
          font-size: 20px;
          line-height: 17px;
        }
    
        label {
          font-family: MADETOMMY-medium;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: normal;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.85);
        }

        button {
          width: 95%;
          padding: 1.5rem 1rem;
          font-size: 18px;
          font-weight: 600;
          border-radius: 12px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
          color: #fff;
          background-color: #390640;
          cursor: pointer;
          outline: none;
    
          .svg {
            outline: none;
          }
          
          @include respond(tab-port) {
            max-width: 375px;
            max-height: 300px;
          }
        }
        &:first-child {
         text-align: right;
        }
    }
    .carouselContainer {
      height: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 90% 10%;

      p {
        font-family: MADETOMMY-regular;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
      }
      .carouselItems {
      
        width: 100%;
        display: flex;
        overflow:hidden ;
      }
      .carouselItem {
        transition: all 0.4s ease;
        flex-shrink: 0;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 60% 40%;
        align-items: center;
        div {
          text-align: center;
          img {
            width:100%;
            height: 100%;
          }
          &:last-child {
            padding: 1rem 0.5rem;
            white-space: pre-line;
          }
        }
      }
      .itemsSelected{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .itemNoSelected ,
      .itemSelected{
        background-color: rgba(57, 6, 64, 0.5);
        padding: 0.4rem;
        border-radius: 50px;
        margin:0.4rem;
      }
      .itemSelected {
        background-color: #390640;
        padding: 0.7rem;
      }
    }

  }
}