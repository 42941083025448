@import "../../utils/colors.scss";
@import "../../utils/media.scss";
@import "../../utils/animation.scss";

.homeContainer {
    z-index: 10;
    position: fixed;
    bottom:0%;
    width: 100%;
    min-height: 30vh;
    background-color: #fff;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    font-family:MADETOMMY;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 10% 70% 15% 5%;
    align-items: center;
    @include respond(tab-port) {
        margin:2rem;
        max-width: 375px;
        max-height: 500px;
        top:0%;
        grid-auto-rows: 15% 15% 25% 10% 35%;
        align-items: center;
    }

    .homeTitle {
        display: none;
        @include respond(tab-port) {
            font-family:MADETOMMY-medium;
            background-color:#390640;
            color:#fff;
            font-size: 24px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            height: 70%;
            align-self: start;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .homeElement {
        @include respond(tab-port) {
            display: none;
        }
        margin:0 auto;
        width: 10%;
        height: 4px;
        background-color: rgb(109,114,120);    
        border-radius: 20px;
    }
    .homeMenu {
        width:100%;
        height: 90%;
        padding: 1rem;
        display: grid;
        grid-template-columns: 30% 30% 30%;
        grid-auto-rows: 100%;
        justify-content: center;
        column-gap: 1rem;
        div {
            padding: 1rem 0 ;
            background-color: #fff;
            border-radius: 13px;
            box-shadow: 0px 2px 21px 0px rgba(0,0,0,0.1);
         p{
            font-size: 10px;
            margin-top:1rem;
            text-align: center;
         }
        }
    }

    span {
        color:rgba(0,0,0,0.85);
        strong {
            color:red;
        }
    }

}